import React from 'react';
import { withTrans } from '../i18n/withTrans';
import AppLayout from '../components/AppLayout';
import SEO from '../components/seo';

const IndexPage = () => (
	<AppLayout>
		<SEO title="Home"/>
		<h1>Hi people</h1>
		<p>Welcome to your new Gatsby site with ant design! (antd)</p>
		<p>You can find all Ant Design components <a href="https://ant.design/components/button/">here</a></p>
		<p>Scroll down if you haven't already!</p>
	</AppLayout>
);

export default withTrans(IndexPage);
